import {
	AiOutlineGithub,
	AiOutlineTwitter,
	AiFillLinkedin,
	AiFillMediumCircle,
} from 'react-icons/ai';
import AppFooterCopyright from './AppFooterCopyright';

const socialLinks = [
	{
		id: 1,
		icon: <AiFillMediumCircle />,
		url: 'https://exav.medium.com',
	},
	{
		id: 2,
		icon: <AiOutlineGithub />,
		url: 'https://github.com/ubaidillahhf',
	},
	{
		id: 3,
		icon: <AiOutlineTwitter />,
		url: 'https://twitter.com/ubaidillahhf',
	},
	{
		id: 4,
		icon: <AiFillLinkedin />,
		url: 'https://www.linkedin.com/in/ubaidillahhf',
	}
];

const AppFooter = () => {
	return (
		<div className="container mx-auto">
			<div className=" sm:pt-30 pb-9 mt-20 border-primary-light dark:border-secondary-dark">
			{/* <div className="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark"> */}
				{/* Footer social links */}
				<div className="font-general-regular flex flex-col justify-center items-center mb-12">
					{/* <p className="text-3xl sm:text-4xl text-primary-dark dark:text-primary-light mb-5">
					🧐 Find me at
					</p> */}
					<ul className="flex gap-4 sm:gap-8">
						{socialLinks.map((link) => (
							<a
								href={link.url}
								target="__blank"
								key={link.id}
								className="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-300"
							>
								<i className="text-xl sm:text-2xl md:text-3xl">
									{link.icon}
								</i>
							</a>
						))}
					</ul>
				</div>

				<AppFooterCopyright />
			</div>
		</div>
	);
};

export default AppFooter;
